<template>
  <!-- counter -->
  <div>
    <v-container grid-list-lg class="carder">
      <v-tabs
        centered
        color="blue darken-1"
        background-color="transparent"
        grow
      >
        <v-tab
          v-for="(value, index) in navigation"
          :key="index"
          :to="value.path"
          >{{ value.title }}</v-tab
        >
      </v-tabs>
      <div v-for="(value, index) in navigation" :key="index" class="min-h">
        <div v-if="Route === value.name">
          <component :is="value.component" />
        </div>
      </div>
      <!-- <h2 class="blue-grey--text nunito lighten-3"><b>Subscription</b></h2>
      <v-divider class="mb-2"></v-divider>
      <div class="mt-8 px-3">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Billplz Collection ID"
              outlined
              color="info"
              :value="settings.billplz_collection"
              @input="updateInput($event, 'billplz_collection')"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Billplz Payout ID"
              outlined
              color="info"
              :value="settings.billplz_payout"
              @input="updateInput($event, 'billplz_payout')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Billplz Token"
              outlined
              color="info"
              :value="settings.billplz_token"
              @input="updateInput($event, 'billplz_token')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Ejen2u Billplz Collection ID"
              outlined
              color="info"
              :value="settings.ejen2u_billplz_collection"
              @input="updateInput($event, 'ejen2u_billplz_collection')"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Ejen2u Billplz Credit Collection ID"
              outlined
              color="info"
              :value="settings.ejen2u_billplz_credit_collection"
              @input="updateInput($event, 'ejen2u_billplz_credit_collection')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Ejen2u Billplz Token"
              outlined
              color="info"
              :value="settings.ejen2u_billplz_token"
              @input="updateInput($event, 'ejen2u_billplz_token')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3 class="blue-grey--text nunito lighten-3 mb-2">
              <b>Sandbox</b>
            </h3>
            <v-btn-toggle
              class="transparent"
              :value="settings.sandbox"
              @input="updateInput($event, 'sandbox')"
            >
              <v-btn :value="true">
                <span class="hidden-sm-and-down">True</span>
              </v-btn>

              <v-btn :value="false">
                <span class="hidden-sm-and-down">False</span>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Trial (Days)"
              outlined
              color="info"
              :value="settings.trial"
              @input="updateInput($event, 'trial')"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="flex justify-end mt-5">
          <v-btn color="success" @click="save">save</v-btn>
        </div>
      </div> -->
      <!-- <div class="mt-8 px-3">
        <h2 class="blue-grey--text nunito lighten-3"><b>Plan</b></h2>
        <v-divider class="mb-2"></v-divider>
      </div> -->
    </v-container>
  </div>
</template>

<style>
.v-text-field__details {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DashboardSettings",

  data() {
    return {
      navigation: [
        {
          name: "detail",
          path: "/dashboard/settings/detail",
          title: "Settings Detail",
          component: () => import("./Detail"),
        },
        {
          name: "subs",
          path: "/dashboard/settings/subs",
          title: "Subscription",
          component: () => import("./Subcription"),
        },
        // {
        //   name: "outlets",
        //   path: "/impersonate/overall/outlets",
        //   title: "Outlets",
        //   component: () => import("./components/Outlets"),
        // },
      ],
      Route: this.$route.params.param,
      // {
      //   name: "Melissa",
      //   store: "Miko cafe",
      // },
    };
  },

  computed: {
    ...mapGetters({
      settings: "getGlobalSettings",
    }),
  },

  watch: {
    $route: "updateRoute",
  },

  methods: {
    getOut(i, v) {
      console.log(i, v);
    },

    updateRoute() {
      this.Route = this.$route.params.param;
    },
  },

  beforeMount() {
    this.$store.dispatch("fetchGlobal");
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.pad {
  margin-top: 3px;
}
.min-h {
  max-height: 81vh;
  overflow-y: auto;
}
.w-75 {
  width: 75%;
}
</style>
